@use '@workspace/4Z1.uikit.react/src/scss/' as *;

.ortophoto__container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;
}

.card-title {
  width: 100%;

  .input {
    @include ellipsis;
  }
}

.ortophoto-map {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;

  &__delimeter {
    width: 100%;
  }

  &__dropdown {
    max-width: 300px;
  }

  &__layout {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &__container {
    display: flex;
    height: 100%;
    margin-top: 16px;
    gap: 8px;

    & .cardWithActionsText {
      color: $text-disabled;
    }
  }

  .map-tools {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 3;
    padding: 8px 20px;
    justify-content: flex-end;
    background-color: rgba($color: $bg-primary, $alpha: 0.5);
    backdrop-filter: blur(10px);
  }
}
