@use '@workspace/4Z1.uikit.react/src/scss/' as *;

.fileProgressContainer {
  display: flex;
  flex-direction: column;
  gap: 4px;
  
  .progressInfo{
    padding: 0;
    margin: 0;
    @include fnt($regular-caption2, $text-secondary);
  }
}

