@use '@workspace/4Z1.uikit.react/src/scss/' as *;

.layersPanel {
  display: flex;
  gap: 16px;
  z-index: 15;
  height: 620px;
  width: 392px;

  &Content {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  &Maps {
    display: flex;
    flex-direction: column;

    &Title {
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include fnt($regular-headline2, $text-primary);
    }

    &List {
      display: flex;
      align-items: flex-start;
      align-content: flex-start;
      gap: 8px 2px;
      align-self: stretch;
      flex-wrap: wrap;
      padding: 16px 0;

      &Item {
        display: flex;
        width: 70px;
        flex-direction: column;
        align-items: center;
        gap: 2px;

        &Name {
          @include fnt($regular-caption2, $text-secondary);
          text-align: center;
        }

        &Image {
          width: 48px;
          height: 48px;
          flex-shrink: 0;
          border-radius: 10px;
          object-fit: cover;
        }
      }
    }
  }

  &List {
    position: relative;
    height: 100%;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    overflow: hidden;

    &Title {
      @include fnt($regular-headline2, $text-primary);
    }

    &Container {
      height: calc(100% - 37px);
    }
  }

  .active {
    outline: 2px solid $bg-accent;
  }
}

