@use "@workspace/4Z1.uikit.react/src/scss/" as *;

.sidebarMenu {
  &__logo {
    width: 44px;
    height: 44px;
    margin-bottom: 14px;
  }

  &__navContainer {
    max-height: 691px;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    .navContainer__link {
      display: flex;
      flex-direction: column;
      gap: 4px;
      align-items: center;
      justify-content: center;
      width: 100%;
      padding: 7px 5px;
      color: $text-disabled;
      border-radius: 10px;
  
      text-align: center;
      font: $regular-caption2;

      &:hover {
        color: $white-main;
        background-color: $bg-primary-hover;
      }
      &.active {
        color: $white-main;
        font: $bold-caption2;
        background-color: $bg-primary-hover;
      }

      &-icon {
        width: 24px;
        height: 24px;
        object-fit: contain;
        svg {
          width: 24px;
          height: 24px;
        }
      }
    }
  }
}
