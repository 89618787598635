@use './base-fonts' as *;
$regular-headline1: 16px/150% $Regular;
$bold-headline1: 16px/150% $Bold;

$regular-subheadline: 14px/150% $Regular;
$bold-subheadline: 14px/150% $Medium;

$regular-headline2: 14px/140% $Regular;
$bold-headline2: 14px/140% $ExtraBold;

$regular-body: 14px/150% $Regular;
$bold-body: 14px/150% $SemiBold;

$regular-caption1: 12px/150% $Regular;
$bold-caption1: 12px/150% $SemiBold;

$regular-caption2: 10px/120% $Regular;
$bold-caption2: 10px/120% $SemiBold;

$regular-title1: 32px/120% $Regular;
$bold-title1: 32px/120% $Bold;

$regular-title2: 24px/150% $Regular;
$bold-title2: 24px/120% $SemiBold;

$regular-title3: 20px/150% $Regular;
$bold-title3: 20px/150% $Medium;

$regular-subtitle1: 14px/150% $Regular;
$bold-subtitle1: 14px/150% $Bold;

$regular-subtitle2: 12px/150% $Regular;
$body-subtitle2: 12px/150% $SemiBold;
