@use '@workspace/4Z1.uikit.react/src/scss/' as *;

.baseAppLayout {
  width: 100%;
  background-color: $bg-faint;
  overflow: hidden;
  display: flex;

  .sidebarLeft {
    width: 332px;
    padding: 20px 16px;
    background-color: $bg-primary;
    box-sizing: content-box;
  }
  
  // Временный стиль для поддержки страниц со скроллящимся сайдбаром
  // Подробнее см. в описании к компоненту ./BaseAppLayout
  .scrollable {
    @include scroll;
    overflow-y: auto;
  }
  
  .container {
    width: 100%;
    position: relative;
  }
}
