@use '@workspace/4Z1.uikit.react/src/scss/' as *;

.heat-layer-tools {
  display: flex;
  gap: 4px;
  height: 40px;

  position: absolute;
  top: 7px;
  left: 20px;
  z-index: 5;

  &__control-list {
    display: flex;
    gap: 16px;

    background-color: $bg-secondary;
    padding: 8px 16px;
    border-radius: 8px;
  }

  &__presentation-type {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__label {
    @include fnt($regular-headline1, $text-primary);

    &--fixed {
      width: 100px;
    }

    &--caption {
      @include fnt($regular-caption1, $text-primary);
    }
  }

  &__control {
    display: flex;
    gap: 8px;
    align-items: center;

    input {
      width: 100px;
      background-color: transparent;
    }
  }
}
