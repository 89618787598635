.sidebarOutlet {
  display: flex;
  height: 100vh;
}
.sidebarOutlet > * {
  flex: 1;
}

.sidebarContainer {
  flex: 0 108px;
}
