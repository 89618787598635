@use '@workspace/4Z1.uikit.react/src/scss/' as *;

.tilesModal {
  &Title {
    @include fnt($regular-body, $text-primary);
  }

  &Info {
    @include fnt($regular-caption1, $text-secondary);
  }

  &Footer {
    display: flex;
    justify-content: end;
    gap: 8px;
    padding: 8px;
  }
}
