.exception {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .exception__title {
    max-width: 50vmin;
  }
}
