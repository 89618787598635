@use '@workspace/4Z1.uikit.react/src/scss/' as *;

.changeCoordinatesButton {
  width: 100%;
}

.changeCoordinatesForm {
  width: 35vmin;
  
  &__form-item {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  
  &__label {
    @include fnt($regular-body, $text-secondary);
  }
}
