@use '@workspace/4Z1.uikit.react/src/scss' as *;
.totalPercentUploadContainer {
  position: relative;
  .button{
    padding: 6px;
  }

  .uploadPercentListContainer{
    min-width: 330px;
    width: 330px;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 50vh;
    @include scroll();

    .modalPopupPercentContainer{
      display: flex;
      flex-direction: column;
      position: relative;
    }
  
    .modalPopup__content{
      padding: 0;
      border: none;
    }

    .uploadFileListContainer{
      padding: 8px;
      background-color: inherit;
    }


    .uploadPercentModalContainerButton{
      position: sticky;
      bottom: -1px;
      left: 0;
      width: 100%;
      height: 100%;
      padding: 8px;

      background-color: $bg-primary;
      .uploadPercentModalButton{
        width: 100%;
      }
    }
   
  }
}
