@use '@workspace/4Z1.uikit.react/src/scss/' as *;

.pageLayout {
  width: 100%;
  background-color: $bg-faint;
  overflow: hidden;
  display: flex;
  height: 100%;

  .sidebarLeft {
    width: 332px;
    padding: 20px 16px;
    background-color: $bg-primary;
    box-sizing: content-box;
  }
  
  .container {
    width: 100%;
    position: relative;
  }
}
