@use "@workspace/4Z1.uikit.react/src/scss/" as *;
.sidebarMenu {

  &__logo{
    width: 100%;
    height: 48px;
  }
  &__container {
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding: 20px 8px;
    background-color: $bg-side_bar;
    border: 1px solid $border-primary;
  }

  &__navContainer {
    padding: 12px 0;
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  &__upContainer {
    width: 100%;
    text-align: center;
  }

  &__navContainer {
    padding: 12px 0;
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  &__footer {
    width: 63px;
    color: $text-disabled;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    text-align: center;
  }

}
