@use '@workspace/4Z1.uikit.react/src/scss/' as *;
.contextMenu {
  position: absolute;

  top: 100px;
  left: 100px;
  z-index: 1;

  .coordinates {
    @include fnt($regular-body, $text-disabled);
  }
}
