@use '@workspace/4Z1.uikit.react/src/scss/' as *;

.searchOnArea  {
  width: 300px;
}

.FlightsByDateAndArea {
  display: flex;
  width: 100%;
  padding: 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;

  border-radius: 8px;
  border: 1px solid $border-primary;
  background: $bg-primary;

  margin-bottom: 16px;

  &Header {
    display: flex;
    width: 100%;
    min-height: 30px;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
  }

  &Filters {
    display: flex;
    width: 100%;
    align-items: center;
    align-content: center;
    gap: 2px 4px;
    flex-wrap: wrap;
  }

  &List {
    max-height: 459px;
  }
}
