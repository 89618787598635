.flightContentBar {
  display: flex;
  justify-content: space-between;
  width: 100%;

  .start {
    width: 100%;
    display: flex;
    gap: 8px;
  }

  .end {
    display: flex;
    gap: 8px;
  }
}
