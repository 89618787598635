@use '@workspace/4Z1.uikit.react/src/scss/' as *;

.mouse-position-info  {
  @include fnt($regular-subheadline, $text-primary);

  position: absolute;
  bottom: 10px;
  right: 10px;
  z-index: 1;

  display: inline-flex;
  justify-content: center;
  align-items: center;

  background-color: $bg-header;
  border-radius: 4px;
  padding: 4px 8px;

  white-space: nowrap;
  min-width: 150px;

  &:empty {
    display: none;
  }
}
