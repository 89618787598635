@use '@workspace/4Z1.uikit.react/src/scss/' as *;

.text-panel {
    @include fnt($regular-subheadline, $text-primary);
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 1;
    background-color: rgba($black-main, 0.6);
    border-radius: 4px;
    backdrop-filter: blur(2px);
    padding: 4px 8px;
    bottom: 10px;
    right: 10px;
}