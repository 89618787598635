@use '@workspace/4Z1.uikit.react/src/scss/' as *;

.dropzoneContainer {
    border: 2px dashed $border-primary;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 12px;
    cursor: pointer;

    margin: 0 auto;
    background-color: $bg-primary;
    border-radius: 10px;
    transition: .1s ease-in-out;

    &.opacity {
        background-color: rgba($color: $bg-primary, $alpha: .8);

        .dropzoneTitle{
            color: $bg-accent;
        }
 
        &.dragging{
            background-color: rgba($color: $bg-primary, $alpha: .8);
            border: 2px dashed $icon-accent;
        }
    }
    
    &.size-small{
        width: 360px;
        min-height: 160px;
    }

    &.size-big{
        width: 615px;
        min-height: 340px;
    }

    &:hover{
        background-color: $bg-primary-hover;
    }

    &.dragging{
        background-color: $bg-primary-hover;
        border: 2px dashed $icon-accent;
    }

    &.disabled{
        pointer-events: none;
        background-color: $bg-pressed-disable;
        
        &.transparent {
            background-color: transparent;
        }
        
        &.disableBg {
            background-color: $bg-primary;
        }

        &:not(.transformText) {
            .dropzoneTitle {
              @include fnt($regular-title1, $text-disabled);
              margin: 0;
            }
            .dropzoneTitleSpan {
              @include fnt($regular-title1, $text-disabled);
              margin: 0;
            }
        }
    }

    .dropzoneTitle{
        @include fnt($regular-headline1, $text-secondary);
        margin: 0;
        max-width: 306px;
    }
    .dropzoneTitleSpan{
        @include fnt($regular-headline2, $text-accent);
        margin: 0;
        max-width: 306px;
    }
    .dropzoneText{
        @include fnt($regular-subtitle2, $text-disabled);
        margin: 0;
        max-width: 306px;
    }

    .dropzoneButtonContainer{
        display: flex;
        align-items: center;
        gap: 8px;
    }
}
