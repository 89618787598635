@font-face {
  font-family: 'Inter Regular';
  src: url('./assets/fonts/Inter-Regular.woff2') format('woff2');
}
@font-face {
  font-family: 'Inter-SemiBold';
  src: url('./assets/fonts/Inter-SemiBold.woff2') format('woff2');
}
@font-face {
  font-family: 'Inter-Bold';
  src: url('./assets/fonts/Inter-Bold.woff2') format('woff2');
}
@font-face {
  font-family: 'Inter-Medium';
  src: url('./assets/fonts/Inter-Medium.woff2') format('woff2');
}
@font-face {
  font-family: 'Inter-ExtraBold';
  src: url('./assets/fonts/Inter-ExtraBold.woff2') format('woff2');
}
$Regular: 'Inter Regular';
$SemiBold: 'Inter-SemiBold';
$Bold: 'Inter-Bold';
$Medium: 'Inter-Medium';
$ExtraBold: 'Inter-ExtraBold';
