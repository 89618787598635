@use '@workspace/4Z1.uikit.react/src/scss/' as *;

$sider-user-height: 50px;

html,
body,
#root {
  height: 100%;
  overflow-x: hidden;
}

.colorWeak {
  filter: invert(80%);
}

.ol-rotate {
  display: none !important;
}

.ant-layout {
  min-height: 100vh;
}

canvas {
  display: block;
}

body {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

ul,
ol {
  list-style: none;
}

// .ant-menu-item.ant-menu-item-selected {
//   background-color: transparent;
//   border-left: 4px #9a794c solid;
// }

// .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
//   background-color: transparent;
// }

.ant-menu-dark .ant-menu-inline.ant-menu-sub{
  background: #314657;
}


.ant-pro-layout .ant-layout-sider.ant-pro-sider{
  background: #314657;

  a{
    color:#fff;
  }
}

.video-camera-icon {
  display: flex;
  justify-content: center;
  align-items: center;

  &.online {
    svg path {
      fill: red;
    }
  }
}


.ant-menu-item a{
  color: rgba(255, 255, 255, .5) !important;
  &:hover{
    color: #fff;
  }
}

.ant-menu-vertical{

  .ant-menu-item-active {
    background-color: #f7f7f7;
    span{
      color: #9a794c !important;
    }
  }
  .ant-menu-submenu-active {
    background-color: #f7f7f7;
    span{
      color: #9a794c !important;
    }
  }
  span{
    color: #000;
  }
}

.ant-menu-item-selected{
  background-color: transparent !important;
}


.ant-menu-submenu-title {
  color: #9a794c;
}

.ant-menu-submenu-popup {
  background-color: #fff !important;
  .ant-menu-item-disabled{
    span{
      color: #cecece !important;
    }
  }
}

// Отключает браузерные стили автозаполнения форм
input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 0s 600000s, color 0s 600000s !important;
}

.ant-menu-inline.ant-menu-root .ant-menu-item, .ant-menu-inline.ant-menu-root .ant-menu-submenu-title.active{
  background-color: transparent;
}

.ant-layout-sider-collapsed {
  .custom-sider-logo > img {
    display: block;
    height: 20px !important;
    margin: 0 auto 0 auto !important;
    transition: height 0.15s ease-out;
  }

  .custom-sider-user-name {
    display: none;
  }
}

.custom-sider-logo {
  width: 100%;
}

.ant-pro-sider-logo > .custom-sider-logo > img {
  display: block;
  height: 160px;
  margin: auto 24px auto 24px;
  transition: height 0.15s ease-out;
}

.custom-sider-user {
  color: #fff;
  line-height: $sider-user-height;
}

.customAltChartTooltip {
  padding: 8px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 2px 2px 4px -1px rgba(0, 0, 0, 0.48);
}

.ant-pro-sider-collapsed-button {
  border: none;
}

.justScroll .justScroll-bar--track::before {
  background-color: rgba(3, 3, 3, 0.1);
}

.justScroll .justScroll-bar--thumb {
  background-color: rgba(3, 3, 3, 0.3);
}

.justScroll .justScroll-bar {
  width: 3px;
}

.justScroll .justScroll-bar:hover .justScroll-bar--thumb,
.justScroll .justScroll-bar:active .justScroll-bar--thumb {
  background-color: rgba(3, 3, 3, 0.6);
}

.activeAssetCard.ant-card-small .ant-card-body {
  padding: 0;
}

.activeAssetCard {
  .ant-list-item-meta-title {
    margin-bottom: 0;
  }

  .activeAssetCard-assetListItem:hover {
    background-color: #f7f7f7;
    cursor: pointer;
  }

  .activeAssetCard-assetListItem:active {
    background-color: #e3e3e3;
    cursor: pointer;
  }
}

.mapboxgl-marker :hover {
  cursor: pointer;
}

.marginless-page-container .ant-pro-page-container-children-content {
  margin: 0;
}

.thin-scrollbar::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background-color: #dedede;
}
.thin-scrollbar::-webkit-scrollbar-thumb {
  background: #757575;
}
.thin-scrollbar {
  //noinspection CssUnknownProperty
  scrollbar-width: thin;
}


.op-context-panel  {
  display: none;
}

.op-left-controls .op-volume-controller {
  display:none;
}
.op-right-controls .setting-holder {
  display:none;
}

button {
  cursor: pointer;
}

.ant-list-item .right {
  text-align: right;
  position: absolute;
  right: 16px;
}
.ant-list-item p {
  margin: 0;
}
.ant-list-item p:hover, .ant-list-item a:hover, .ant-list-item span:hover {
  cursor: pointer;
  color: #9a794c;
}
.ant-list-item span.date:hover, .ant-list-item span.type:hover {
  cursor: inherit;
  color: inherit;
}
.ant-list-item ul {
  padding-left: 1rem;
}

.flight-child.show, .flight-child-video.show, .flight-child-gaz.show {
  display: block!important;
}

ul.flight-child, ul.flight-child-video, ul.flight-child-gaz {
  list-style: none;
  padding: 0.2rem 0 0.2rem 0.5rem;
  margin: 0;
}

.flight-child a, .flight-child-video a, .flight-child-gaz a {
  overflow-x: hidden;
}

.ant-picker-range, .ant-select, .group-checkbox {
  width: 100%;
  margin-bottom: .5rem!important;
}

.marginless-page-container {
  padding: 1rem;
}

.page-header{
  text-align: center;
}
.ant-select-selection-item .ant-avatar-image, .ant-select-item-option-content .ant-avatar-image{
  width: 20px;
  height: 20px;
  margin-top: -5px;
}
.ant-select-item-option-content svg {
  margin-bottom: -5px;
}
.ant-form-item-label{
  text-align: left!important;
}
.w-100{
  width: 100%;
}

.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
// TODO fix umi update
.ant-pro-layout .ant-pro-layout-content {
  margin: 0;
  padding: 0;
}

/*.ant-menu-inline-collapsed-tooltip {
  display: none;
}*/

/*.ant-layout-header {
  width: 0!important;
  height: 0!important;
}*/

.ant-layout-sider-children {
  .ant-menu-title-content{
    color: rgba(255, 255, 255, 0.5) !important;
  }

  .ant-menu-item-selected{
    .ant-menu-title-content{
      color: rgba(255, 255, 255, 1) !important;
    }
  }
}

.ant-menu-item.ant-menu-item-active {
  a{
    color: #fff !important;
  }
}
