@use '@workspace/4Z1.uikit.react/src/scss' as *;

@mixin default-map-styles() {
  position: relative;
  width: 100%;
  height: 100%;
}

@mixin map-tools() {
  position: absolute;
  z-index: 2;
  background: $bg-header;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 8px 20px;
}

.MapEngine {
  width: 100%;
  height: 100%;
  z-index: 1;

  position: absolute;
}

.map-tools {
  display: flex;
  gap: 4px;

  &-left {
    margin: 0 20px;
  }
}

.gasMap {
  @include default-map-styles();

  .map-tools {
    @include map-tools();
  }
}

.simpleMapCard {
  @include default-map-styles();
  padding: 8px;
  margin-top: 8px;

  .ol-viewport {
    border-radius: 4px;
  }
  
  .map-tools {
    @include map-tools();
  }
}
