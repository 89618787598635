@use '@workspace/4Z1.uikit.react/src/scss/' as *;

/**
    @deprecated

    // Компонент со старыми стилями компонент верхней панели контролов
    // Временно перенесены сюда из файла gaz.scss, с целью быстрого исправления стилей
    // Расширять и дополнять стили - запрещено, вместо этого необходимо вынести компонент в UI-кит
*/

input[type='range'] {
  -webkit-appearance: none;
}
input[type='range']:focus {
  outline: none;
}
input[type='range']::-webkit-slider-runnable-track {
  width: 100%;
  height: 6px;
  cursor: pointer;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  background: $icon-disabled;
  border-radius: 4px;
  border: 0.2px solid #010101;
}
input[type='range']::-webkit-slider-thumb {
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  border: 1px solid $icon-secondary;
  height: 12px;
  width: 12px;
  border-radius: 12px;
  background: $bg-primary;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -4px;
}
input[type='range']::-moz-range-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  background: #ffffff;
  border-radius: 1.3px;
  border: 0.2px solid #010101;
}
input[type='range']::-moz-range-thumb {
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  border: 1px solid #000000;
  height: 20px;
  width: 8px;
  border-radius: 3px;
  background: #ffffff;
  cursor: pointer;
}
input[type='range']::-ms-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  border-width: 16px 0;
  color: transparent;
}
input[type='range']::-ms-fill-lower {
  background: linear-gradient(to right, #0f0, #ff0, #f00);
  border: 0.2px solid #010101;
  border-radius: 2.6px;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
}
input[type='range']::-ms-fill-upper {
  background: linear-gradient(to right, #0f0, #ff0, #f00);
  border: 0.2px solid #010101;
  border-radius: 2.6px;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
}
input[type='range']::-ms-thumb {
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  border: 1px solid #000000;
  height: 20px;
  width: 8px;
  border-radius: 3px;
  background: #ffffff;
  cursor: pointer;
}
input[type='range']:focus::-ms-fill-lower {
  background: #ffffff;
}
input[type='range']:focus::-ms-fill-upper {
  background: #ffffff;
}
input.filter::-webkit-slider-runnable-track {
  background: linear-gradient(to right, #00f, #0ff, #0f0, #ff0, #f00);
}
input.filter::-moz-range-track {
  background: linear-gradient(to right, #00f, #0ff, #0f0, #ff0, #f00);
}
