@use '@workspace/4Z1.uikit.react/src/scss/' as *;

.uploadFileListContainer {
  background-color: $bg-secondary-disable;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  max-width: 615px;
  width: 100%;

  .uploadFileListHeader{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    padding-right: 8px;

    .fileListTitleContainer{
      display: flex;
      align-items: center;
      gap: 8px;
  
      .uploadFileListTitle{
        @include fnt($regular-headline2, $text-primary);
        text-transform: uppercase;
        margin: 0;
      }
    }
  }
  
  .errorMessage{
    margin: 0;
    @include fnt($regular-subheadline, $text-critical);
  }

  .loader {
    width: 100%;
    height: 62px;
  }

  &.small{
    padding: 8px;
    gap: 8px;
  }

  .uploadFileListGroup{
    &:first-child{
      padding-bottom: 16px;
      position: relative;
      &::after{
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        content: '';
        width: 100%;
        height: 2px;
        background-color: $border-secondary;
      }
    }
    &:last-child{
      padding-top: 16px;
      position: relative;
      &::after{
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        content: '';
        width: 100%;
        height: 2px;
        background-color: $border-secondary;
      }
    }
  }
}
