$white-main: #ffffff;
$white-1200m: #ffffff;
$white-1100: #F6F6F6;
$white-1000: #ECEDEE;
$white-900: #E2E3E4;
$white-800: #D9D9D9;
$white-750:  rgba($white-main, 0.6);
$white-700: #A1A4A7;
$white-600: #71767A;
$white-550: rgba(#0A1118, 0.6);
$white-500: #30373D;
$white-400: #262D33;
$white-300: #1C242B;
$white-200: #131B22;
$white-100: #0A1118;

$black-main: #131b22;
$black-700: #0a1118;
$black-600: rgba($black-main, 0.88);
$black-500: rgba($black-main, 0.6);
$black-400: rgba($black-main, 0.4);
$black-300: rgba($black-main, 0.12);
$black-200: rgba($black-main, 0.08);
$black-100: rgba($black-main, 0.04);

$blue-1500: #F6FAFE;
$blue-1400: #EDF5FD;
$blue-1300: #E3EFFB;
$blue-1200: #A2CAF5;
$blue-1100: #74AFEF;
$blue-1000: #338AE8;
$blue-900m: #177AE5;
$blue-800: #166ECD;
$blue-700: #125FB2;
$blue-600: #165497;
$blue-500: #0D4480;
$blue-400: #144170;
$blue-300: #142739;
$blue-200: #142331;
$blue-100: #131F2A;
$blue-main: #177ae5;

$red-1500: #FEF7F7;
$red-1400: #FDEFEF;
$red-1300: #FBE5E5;
$red-1200: #F5ABAB;
$red-1100: #EF8282;
$red-1000: #E84747;
$red-900m: #E52E2E;
$red-800: #CB2B2C;
$red-700: #B22424;
$red-600: #91272A;
$red-500: #801A1A;
$red-400: #672226;
$red-300: #2C1D23;
$red-200: #241D23;
$red-100: #1B1C23;
$red-main: #e52e2e;

$orange-1500: #FFFAF6;
$orange-1400: #FEF6ED;
$orange-1300: #FDF0E3;
$orange-1200: #FACEA3;
$orange-1100: #F7B674;
$orange-1000: #F49434;
$orange-900m: #F28518;
$orange-800: #D77819;
$orange-700: #BF6913;
$orange-600: #995B1C;
$orange-500: #8C4D0E;
$orange-400: #6C451E;
$orange-300: #2E2821;
$orange-200: #252421;
$orange-100: #1C1F22;
$orange-main: #f28518; 

$green-1500: #F7FDF8;
$green-1400: #EEFBF1;
$green-1300: #E5F8EA;
$green-1200: #A9EBB9;
$green-1100: #7FE097;
$green-1000: #43D266;
$green-900m: #29CC51;
$green-800: #26B64B;
$green-700: #1F993D;
$green-600: #21853F;
$green-500: #146629;
$green-400: #1B6234;
$green-300: #163028;
$green-200: #152925;
$green-100: #142224;
$green-main: #29cc51;

$only-bg-gray: #313940;
$temporarily-1500m: #D1D9E0;
$temporarily-900m: #313940;