@use '@workspace/4Z1.uikit.react/src/scss/' as *;

.assetCardTooltips {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(30px, 1fr));
  align-items: center;
  gap: 2px;

  .assetCardTooltip {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    gap: 10px;
    flex: 1 0 0;
    cursor: pointer;
    width: 100%;

    border-radius: 4px;
    border: 1px solid $border-primary;
    background-color: $bg-secondary;
  }

  .assetCardTooltipInfo {
    cursor: auto;

  }
}

.movementTrackButton {
  width: 100%;
}

.movementTrack {
  position: relative;

  .movementTrackButtonContainer {
    display: flex;
    align-items: center;
    gap: 2px;
  }

  .movementTrackPopup {
    top: 34px;
    left: 0;
  }
}

.ptzActions {
  display: flex;
  gap: 2px;
  width: 100%;
}

.assetRedirectButton {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 4px;

  .redirectText {
    @include fnt($regular-caption1, $text-primary);
  }
}

.openDetailsPageLink {
  width: 100%;
}

.diagnosticAuvList {
  display: flex;
  flex-wrap: wrap;

  svg path {
    fill: red;
  }
}
