@use '@workspace/4Z1.uikit.react/src/scss/' as *;

.uploadFilesContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    gap: 20px;
    overflow-y: auto;
    padding-top: 50px;
    padding-right: 10px;
    padding-bottom: 80px;
    @include scroll();

    .fileList {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &:has(> :only-child) {
        justify-content: center;
    }
}
.absoluteDropzoneContainer {
    position: absolute;
    padding: 16px;
    z-index: 1000;

    .absoluteDropzone{
        width: 48px;
        height: 48px;

        svg {
            path {
                fill: $bg-accent;
            }
        }
    }
}

.uploadFilesFlightContainer{
    width: 100%;
    height: 100%;
    .dropzoneFlight{
        margin: 0;
        width: 100%;
        height: 100%;
    }
}
