.zoomPanel {
  display: flex;
  flex-direction: column;
  gap: 4px;
  position: absolute;
  z-index: 1;
}

.baseLayoutTopRight {
  top: 72px; // 56px (высота шапки) + 16px (отступ от шапки) = 72px
  right: 20px;
}

.baseLayoutTopLeft {
  top: 72px; // 56px (высота шапки) + 16px (отступ от шапки) = 72px
  left: 20px;
}

.topRight {
  top: 16px;
  right: 20px;

  &Small {
    top: 4px;
    right: 8px;
  }
}

.topLeft {
  top: 16px;
  left: 20px;
}
