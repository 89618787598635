@use '@workspace/4Z1.uikit.react/src/scss/' as *;

.uploadFileListItemContainer{
    display: flex;
    gap: 12px;
    padding: 8px;
    width: 100%;
    border-radius: 4px;
    transition: .1s ease-in-out;

    &:hover{
        background-color: $bg-primary-hover;
    }

    &.small{
        border-bottom: 2px solid $border-secondary;
        border-radius: 0;
        .uploadErrorText{
            @include ellipsis();
            max-width: 200px;
        }

        &:only-child{
            border: none;
        }
    }

    .uploadFileInfoContainer{
        display: flex;
        flex-direction: column;
        gap: 4px;
        width: 100%;
        .uploadFileTitleContainer{
            display: flex;
            gap: 4px;
    
            .uploadFileName{
                @include fnt($regular-caption1, $text-primary);
                word-break: break-all;
                overflow-wrap: break-word;
                white-space: normal; 
                width: fit-content;
                margin: 0;
            }

            .uploadBadge{
                padding: 0 2px;
            }
    
            .uploadCheck{
                border-radius: 8px;
                width: 16px;
                height: 16px;
                background-color: $icon-success;
                display: flex;
                align-items: center;
                justify-content: center;
    
                path{
                    fill: $text-primary;
                }
            }
            .uploadQueued{
                border-radius: 8px;
                width: 16px;
                height: 16px;
                display: flex;
                align-items: center;
                justify-content: center;
    
                path{
                    fill: $text-primary;
                }
            }
        }

        .uploadErrorText{
            @include fnt($regular-body, $text-critical);
            margin: 0;
        }
    }

    
}